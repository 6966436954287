import axios from "axios";
import { genSaltSync, hashSync } from "bcryptjs";
import * as jose from "jose";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { fromString } from "uint8arrays/from-string";
import useAxios from "../../../../utils/useAxios.js";
import {
    API_URL,
    REACT_APP_HKEY,
    REACT_APP_JKEY,
    REACT_APP_GA_MEASUREMENT_ID
} from "../../../../constants.js";
import Footer from "../../../global_component/Footer/Footer";
import NavRoute from "../../Nav_Route/NavRoute";
import "./HireArtisans.css";
import { Helmet } from "react-helmet-async";
import CityModal from "../../../global_component/Modal/cityModal.js";
import { useStore } from "../../../../store/store.js";
import ReactGA from "react-ga4";
import { useAlert } from "react-alert";
import ServicesCartPopup from "./ServicesCartPopup.js";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

export default function HireArtisans() {
    const [state, dispatch] = useStore();
    const api = useAxios();
    const useQuery = () => new URLSearchParams(useLocation().search);
    const [searchParams, setSearchParams] = useSearchParams();
    const query = useQuery();
    const [servicesCategory, setServicesCategory] = useState([]);
    const alert = useAlert();
    const [isError, setIsError] = useState(false);
    const [isLoadingProducts, setIsLoadingProducts] = useState(true);
    const [material, isMaterial] = useState(query.get("material") === "false" ? 1 : 0)
    const navigate = useNavigate();

    ReactGA.send({
        hitType: 'pageview',
        page: `/hire-artisans`,
        title: 'Hire Artisans'
    })

    useEffect(() => {
        async function fetchData() {
            window.scroll(0, 0)
            console.log(query.get("material"))
            console.log(material)
            setIsLoadingProducts(true);

            var dec = genSaltSync(10);
            var hash = hashSync(REACT_APP_HKEY, dec);
            const secret = fromString(REACT_APP_JKEY);
            const twj = await new jose.SignJWT({ expiry: hash })
                .setProtectedHeader({ alg: "HS256" })
                .setExpirationTime("5s")
                .sign(secret);
            const res = await axios.get(`${API_URL}/services/getSectionwiseServicesCategory`, {
                headers: {
                    "Content-Policy": twj,
                },
            });

            if (res.data?.length === 0) {
                setIsError(true);
            } else {
                setIsError(false);
                setServicesCategory(res.data.filter(data => data.page === "hire-artisans"));
            }
            setIsLoadingProducts(false);

        }
        state.selectedCity && fetchData();
    }, [state.selectedCity]);

    const [showModal, setShowModal] = useState(state.selectedCity ? false : true);
    const closeModalHandler = () => {
        setShowModal(false);
    };

    const openModalHandler = () => {
        setShowModal(true);
    }

    return (
        <>
            <Helmet>
                <meta
                    name="title"
                    content={`Book ${servicesCategory?.serviceCategory} with plypicker at best price in maharashtra | upto 75% discount | your service at your booked time.`}
                />
                <meta
                    name="description"
                    content={`Book ${servicesCategory?.serviceCategory} with plypicker at best price in maharastra and reduce your hassle of searching interior designing related workers from market and get your {sub Category name} delivered at your door step in less than 48 hr so that your work never stops`}
                />
                <meta
                    name="keywords"
                    content="plypicker, ecommerce, interior designer, interior designing, plywood, basin, faucet, hardware, flat, wardrobe, bathroom, kitchen"
                />
                <meta property="og:image" content="LOGO" />
                <link rel="canonical" href="https://plypicker.com/hire-artisans" />
            </Helmet>
            {!state.selectedCity && (
                <>
                    <CityModal onClose={closeModalHandler}></CityModal>
                    <div style={{ display: isLoadingProducts ? "block" : "none" }}>
                        <div style={{ display: "flex", overflowX: "none" }}>
                            <div className="comp4-loading-shimmer">
                                <div className="comp4-shimmer-image"></div>
                                <div className="comp4-shimmer-details">
                                    <div className="comp4-shimmer-line"></div>
                                    <div className="comp4-shimmer-line"></div>
                                    <div className="comp4-shimmer-line"></div>
                                </div>
                            </div>
                            <div className="comp4-loading-shimmer">
                                <div className="comp4-shimmer-image"></div>
                                <div className="comp4-shimmer-details">
                                    <div className="comp4-shimmer-line"></div>
                                    <div className="comp4-shimmer-line"></div>
                                    <div className="comp4-shimmer-line"></div>
                                </div>
                            </div>
                            <div className="comp4-loading-shimmer">
                                <div className="comp4-shimmer-image"></div>
                                <div className="comp4-shimmer-details">
                                    <div className="comp4-shimmer-line"></div>
                                    <div className="comp4-shimmer-line"></div>
                                    <div className="comp4-shimmer-line"></div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="comp4-loading-shimmer">
                                <div className="comp4-shimmer-image"></div>
                                <div className="comp4-shimmer-details">
                                    <div className="comp4-shimmer-line"></div>
                                    <div className="comp4-shimmer-line"></div>
                                    <div className="comp4-shimmer-line"></div>
                                </div>
                            </div>
                            <div className="comp4-loading-shimmer">
                                <div className="comp4-shimmer-image"></div>
                                <div className="comp4-shimmer-details">
                                    <div className="comp4-shimmer-line"></div>
                                    <div className="comp4-shimmer-line"></div>
                                    <div className="comp4-shimmer-line"></div>
                                </div>
                            </div>
                            <div className="comp4-loading-shimmer">
                                <div className="comp4-shimmer-image"></div>
                                <div className="comp4-shimmer-details">
                                    <div className="comp4-shimmer-line"></div>
                                    <div className="comp4-shimmer-line"></div>
                                    <div className="comp4-shimmer-line"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div style={{ display: isLoadingProducts ? "none" : "block" }}>
                <NavRoute onOpen={openModalHandler} productRouter title="Hire Artisans" services />
                <div className="container-fluid">
                    <div className="w-100 py-3 text-center d-flex align-items-center justify-content-center flex-column" style={{ minHeight: "250px", borderRadius: "30px", background: `url(${servicesCategory[material]?.img}) no-repeat center center/cover` }}>
                        <h1 style={{ color: "white", textTransform: "uppercase", fontFamily: "Trirong", fontWeight: 700 }}>Hire Artisans</h1>
                        <p style={{ color: "white", maxWidth: "70%" }}>{servicesCategory[material]?.content}</p>
                    </div>
                    <div className="p-2 mt-4">
                        <h2
                            style={{ textTransform: "uppercase", fontFamily: "Trirong", fontWeight: 700, fontSize: "40px", marginBottom: "22px" }}
                        >
                            Hire Artisans - {servicesCategory[material]?.sectionName}
                        </h2>
                        <div
                            className="d-flex flex-row w-100 flex-wrap justify-content-start"
                            style={{ rowGap: "25px" }}
                        >
                            <>
                                {servicesCategory[material]?.data.map((serviceCategory) => {
                                    return <>
                                        <div className="col-lg-2 col-md-4 col-12 col-sm-6">
                                            <div
                                                className="cardServiceCat box d-flex flex-column mx-2"
                                                onClick={() => {
                                                    navigate(`/hire-artisans/${serviceCategory._id}`);
                                                }}
                                            >
                                                <img
                                                    src={(serviceCategory && serviceCategory?.category_image?.length > 0) && serviceCategory?.category_image[0]}
                                                    alt="" style={{ aspectRatio: 5 / 4 }} />
                                                <span
                                                    style={{
                                                        color: "black",
                                                        fontFamily: "Poppins",
                                                        fontSize: "22px",
                                                        fontWeight: 400,
                                                        textTransform: "uppercase",
                                                        width: "75%"
                                                    }}
                                                >
                                                    {serviceCategory?.serviceCategory}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                }
                                )}
                            </>
                        </div>
                    </div>
                </div>
                <ServicesCartPopup />
            </div>
            <Footer />
        </>
    );
}