import { useNavigate } from "react-router-dom";
import "./Component2.css";
import Turnkey from "./Turnkey";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/autoplay';

export default function Component2({ data, navbar }) {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const onloading = () => {
        setIsLoading(false);
        // }
    };
    return <>
        {<div style={{ display: isLoading ? "block" : "none" }}>
            <div className="comp2-loading-shimmer">
                <div className="comp2-shimmer-image"></div>
                <div className="comp2-shimmer-details">
                    <div className="comp2-shimmer-line"></div>
                    <div className="comp2-shimmer-line"></div>
                    <div className="comp2-shimmer-line"></div>
                </div>
            </div>
        </div>}

        {data && navbar && <>
            <div className="w-100 d-lg-flex flex-row flex-wrap hpComponent2Part1Parent py-3">
                <div className="col-lg-7 col-12 d-none d-lg-flex align-items-center">
                    <div>
                        <h1 className="newHeading">{data?.part1[1].title}</h1>
                        <div className="w-100 d-flex flex-row flex-wrap">
                            {data?.part1[1]?.data.slice(0, 11).map(x => <>
                                <div className="col-lg-2 col-3 px-2 hpComponent2DivWrapper" onClick={() => navigate(x?.redirect)}>
                                    <div className="hpComponent2ImgWrapper w-100 d-flex align-items-center justify-content-center mx-auto">
                                        <img onLoad={() => onloading()} src={x.url} className="mx-auto hpComponent2Image" />
                                    </div>
                                    <p className="truncate text-center newText">{x.title}</p>
                                </div>
                            </>)}
                            <div className="col-lg-2 col-3 px-2 hpComponent2DivWrapper" onClick={() => navigate("/hire")}>
                                <div className="hpComponent2ImgWrapper w-100 d-flex align-items-center justify-content-center mx-auto" style={{ background: "white" }}>
                                    <img src={navbar["down-arrow"]} className="mx-auto hpComponent2Image" />
                                </div>
                                <p className="truncate text-center newText">View more</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 d-lg-none d-sm-block d-none">
                    <div className="w-100 d-flex flex-row align-items-center justify-content-center mt-5 mb-3">
                        {data?.part1.map((x, i) => <>
                            <div className={i === selectedTab ? "gx-1 text-center hpComponent2FilledButton mx-3 d-flex flex-row align-items-center justify-content-center" : "gx-1 text-center hpComponent2OutlinedButton mx-3 d-flex flex-row align-items-center justify-content-center"} onClick={() => { setSelectedTab(i); setIsCollapsed(true); }}>
                                <div className="d-flex align-items-center justify-content-center pe-1"><img onLoad={() => onloading()} src={i === selectedTab ? data?.part1[i]["selected-icon"] : data?.part1[i]["icon"]} style={{ maxWidth: "22.5px" }} /></div><div className="newText">{x?.title}</div>
                            </div>
                        </>)}
                    </div>
                    <div className="w-100 d-flex flex-row flex-wrap align-items-center justify-content-start row-cols-5">
                        {isCollapsed ?
                            <>
                                {
                                    data?.part1[selectedTab]?.data.slice(0, 9).map(x => <>
                                        <div className="col px-2 hpComponent2DivWrapper" onClick={() => navigate(x?.redirect)}>
                                            <div className="hpComponent2ImgWrapper d-flex align-items-center justify-content-center mx-auto">
                                                <img src={x.url} className="mx-auto hpComponent2Image" />
                                            </div>
                                            <p className="truncate text-center newText">{x.title}</p>
                                        </div>
                                    </>)
                                }
                                {data?.part1[selectedTab]?.data.length > 10 && <div className="col px-2 hpComponent2DivWrapper" onClick={() => setIsCollapsed(false)}>
                                    <div className="hpComponent2ImgWrapper d-flex align-items-center justify-content-center mx-auto" style={{ background: "transparent" }}>
                                        <img onLoad={() => onloading()} src={navbar["down-arrow"]} className="mx-auto hpComponent2Image" />
                                    </div>
                                    <p className="truncate text-center newText">Show more</p>
                                </div>
                                }
                            </>
                            :
                            <>
                                {
                                    data?.part1[selectedTab]?.data.map(x => <>
                                        <div className="col px-2 hpComponent2DivWrapper" onClick={() => navigate(x?.redirect)}>
                                            <div className="hpComponent2ImgWrapper d-flex align-items-center justify-content-center mx-auto">
                                                <img onLoad={() => onloading()} src={x.url} className="mx-auto hpComponent2Image" />
                                            </div>
                                            <p className="truncate text-center newText">{x.title}</p>
                                        </div>
                                    </>)
                                }
                                {data?.part1[selectedTab]?.data.length > 10 && <div className="col px-2 hpComponent2DivWrapper" onClick={() => setIsCollapsed(true)}>
                                    <div className="hpComponent2ImgWrapper w-100 d-flex align-items-center justify-content-center mx-auto" style={{ background: "transparent" }}>
                                        <img src={navbar["up-arrow"]} className="mx-auto hpComponent2Image" />
                                    </div>
                                    <p className="truncate text-center newText">Show less</p>
                                </div>}
                            </>
                        }
                    </div>
                </div>
                <div className="col-12 d-sm-none d-block">
                    <div className="w-100 d-flex flex-row align-items-center justify-content-center mt-5 mb-3">
                        {data?.part1.map((x, i) => <>
                            <div className={i === selectedTab ? "text-center hpComponent2FilledButton mx-3" : "text-center hpComponent2OutlinedButton mx-3"} onClick={() => { setSelectedTab(i); setIsCollapsed(true); }}>
                                <div className="newText">{x?.title}</div>
                            </div>
                        </>)}
                    </div>
                    <div className="w-100 d-flex flex-row flex-wrap align-items-center justify-content-center">
                        {isCollapsed ?
                            <>
                                {
                                    data?.part1[selectedTab]?.data.slice(0, 8).map(x => <>
                                        <div className="col-3 px-2 hpComponent2DivWrapper" onClick={() => navigate(x?.redirect)}>
                                            <div className="hpComponent2ImgWrapper w-100 d-flex align-items-center justify-content-center row mx-auto">
                                                <img onLoad={() => onloading()} src={x.url} className="mx-auto hpComponent2Image" />
                                            </div>
                                            <p className="truncate text-center newText">{x.title}</p>
                                        </div>
                                    </>)
                                }
                            </>
                            :
                            <>{
                                data?.part1[selectedTab]?.data.map(x => <>
                                    <div className="col-3 px-2 hpComponent2DivWrapper" onClick={() => navigate(x?.redirect)}>
                                        <div className="hpComponent2ImgWrapper w-100 d-flex align-items-center justify-content-center mx-auto">
                                            <img onLoad={() => onloading()} src={x.url} className="mx-auto hpComponent2Image" />
                                        </div>
                                        <p className="truncate text-center newText">{x.title}</p>
                                    </div>
                                </>)
                            }</>}
                    </div>
                    {data?.part1[selectedTab]?.data.length > 8 && <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                        <div className="col px-2 hpComponent2DivWrapper" onClick={() => setIsCollapsed(!isCollapsed)}>
                            <div className="w-100 d-flex align-items-center justify-content-center mx-auto" style={{ background: "transparent" }}>
                                <img src={isCollapsed ? navbar["down-arrow"] : navbar["up-arrow"]} className="mx-auto hpComponent2Image" style={{ maxWidth: "30px" }} />
                            </div>
                            <p className="my-1 text-center newText">{isCollapsed ? "Show more" : "Show less"}</p>
                        </div>
                    </div>}
                </div>
                <div className="col-lg-5 d-lg-flex d-none flex-row align-items-center justify-content-center" style={{ cursor: "pointer" }}>
                    <div className="d-flex flex-row align-items-center justify-content-center w-100 ps-lg-2">
                        {/* <div className="d-flex flex-row align-items-center justify-content-center"> */}
                        <img src={data?.part2?.banner} className="w-100" onClick={() => navigate(data?.part2?.redirect)} />
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>}
        <div className="w-100 d-flex flex-row flex-wrap compBottomMarginMobile compBottomMarginTablet compBottomMarginDesktop">
            <div className="col-lg-8 col-12">
                <div className="w-100">
                    <Swiper slidesPerView={1} autoplay={{ delay: 2000, disableOnInteraction: false }} modules={[Autoplay]}>
                        {data?.part3.map(x => <>
                            <SwiperSlide>
                                <div className="w-100" style={{ cursor: "pointer" }}>
                                    <img onLoad={() => onloading()} src={x?.url} className="w-100" onClick={() => navigate(x?.redirect)} />
                                </div>
                            </SwiperSlide>
                        </>)}
                    </Swiper>
                </div>
            </div>
            <div className="col-lg-4 ps-lg-3 d-lg-block d-none">
                <Turnkey data={data?.part4} />
            </div>
        </div>
    </>
}