import axios from "axios";
import { genSaltSync, hashSync } from "bcryptjs";
import * as jose from "jose";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { CircularProgress, Pagination } from "@mui/material";
import { Modal } from "react-bootstrap";
import ReactImageMagnify from "react-image-magnify";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fromString } from "uint8arrays/from-string";
import Countdown from "react-countdown";
import CityModal from "../../../global_component/Modal/cityModal";
import ServicesCartPopup from "./ServicesCartPopup.js";
import {
    API_URL,
    REACT_APP_HKEY,
    REACT_APP_JKEY,
    REACT_APP_GA_MEASUREMENT_ID,
} from "../../../../constants.js";
import { useStore } from "../../../../store/store.js";
import useAxios from "../../../../utils/useAxios.js";
import Footer from "../../../global_component/Footer/Footer";
import LoginRegisterPopup from "../../../global_component/LoginRegisterPopup/LoginRegisterPopup.js";
import { ReactComponent as Share } from "../../IndividualProducts/NewProductDetail/img/share.svg";
import StarRating from "../../IndividualProducts/Products_Detail/StarRating/StarRating.js";
import NavRoute from "../../Nav_Route/NavRoute";
import { ReactComponent as Cross } from "./cross.svg";
import IsPopupVisible from "./IsPopupVisible.js";
import "./ServiceCategory.css";
import ReactGA from "react-ga4";
import "./ServiceCategory.css";

// ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function ServiceCategory({ title }) {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const [searchParams, setSearchParams] = useSearchParams();
    const query = useQuery();
    const api = useAxios();
    const alert = useAlert();
    const [state, dispatch] = useStore();
    const [currentImages, setCurrentImages] = useState([]);
    const [image, setImage] = useState("");
    const [selectedImage, setSelectedImage] = useState(0);
    const [servicesCategory, setServicesCategory] = useState([]);
    const [servicesSubCategory, setServicesSubCategory] = useState([]);
    const [isError, setIsError] = useState(false);
    const [hireModal, setHireModal] = useState(false);
    const [isServicesLoading, setIsServicesLoading] = useState(true);
    const { ref, isServicePopupVisible, setIsServicePopupVisible, modalRef } =
        IsPopupVisible(false);
    const [isLoginRegisterPopupVisible, setIsLoginRegisterPopupVisible] =
        useState(false);
    const [isCartNotifPopupVisible, setIsCartNotifPopupVisible] = useState(false);
    const [productDescription, setProductDescription] = useState("");
    const [focusedService, setFocusedService] = useState({});
    const [getPriceTimestamp, setGetPriceTimestamp] = useState(null);
    const [isGettingPrice, setIsGettingPrice] = useState(false);
    const [selectedVariation, setSelectedVariation] = useState({});
    const [allVariations, setAllVariations] = useState({});
    const [servicePrice, setServicePrice] = useState(0);
    const [isTruncated, setIsTruncated] = useState(true);
    const [productName, setProductName] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [cartLength, setCartLength] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [pcharges, setPcharges] = useState(0);
    const [length, setLength] = useState(1);
    const [width, setWidth] = useState(2);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setpage] = useState(1);
    const perPage = 20;


    const [randomprop, setRandomProps] = useState(0);
    const [totalUnits, setTotalUnits] = useState(length * width * quantity);
    const navigate = useNavigate();
    const params = useParams();

    ReactGA.send({
        hitType: "pageview",
        page: `/hire-artisans/:${params?.id}`,
        title: "Service Category",
    });

    function changeVariationHandler(targetVariationName, targetVariationValue) {
        setSelectedVariation({
            ...selectedVariation,
            [targetVariationName]: targetVariationValue,
        });
        if (Object.keys(allVariations)[0] === targetVariationName) {
            let indexOfQueryVar = allVariations[targetVariationName].findIndex(
                (o) => o.attrs[targetVariationName] === targetVariationValue
            );
            if (indexOfQueryVar > -1) {
                setServicePrice(
                    allVariations[targetVariationName][indexOfQueryVar]?.discounted_price
                );
                setProductName(
                    allVariations[targetVariationName][indexOfQueryVar]?.serviceName
                );
                allVariations[targetVariationName][indexOfQueryVar].attrs?.imgs &&
                    setCurrentImages([
                        `${allVariations[targetVariationName][indexOfQueryVar].attrs?.imgs}`,
                    ]);
                allVariations[targetVariationName][indexOfQueryVar].attrs?.imgs
                    ? setImage(
                        `${allVariations[targetVariationName][indexOfQueryVar].attrs?.imgs}`
                    )
                    : setImage(currentImages[0]);
                allVariations[targetVariationName][indexOfQueryVar].attrs?.imgs &&
                    setSelectedImage(0);
            } else {
                setServicePrice(0);
            }
        }
    }

    async function getPrice(id) {
        setIsGettingPrice(true);
        if (state.authenticated) {
            if (state.accessToken === null || !state.authenticated) {
                setIsLoginRegisterPopupVisible(true);
                setIsGettingPrice(false);
            } else {
                const res = await api.get(`${API_URL}/services/getservicesprice`, {
                    headers: {
                        id: id,
                    },
                });
                if (res.data.success === true) {
                    alert.success(res.data.msg);
                    setGetPriceTimestamp(res.data.dueTimestamp);
                    setTimeout(() => {
                        alert.success("Till then you can add this in your wishlist");
                    }, 1250);
                    setIsGettingPrice(false);
                } else {
                    alert.error("An error occoured");
                    setIsGettingPrice(false);
                }
            }
            setIsGettingPrice(false);
        } else {
            setIsLoginRegisterPopupVisible(true);
            setIsGettingPrice(false);
        }
    }

    function deselectVariationHandler(targetVariationName, targetVariationValue) {
        let newVariationObject = selectedVariation;
        delete newVariationObject[targetVariationName];
        setSelectedVariation({ ...newVariationObject });
        setServicePrice(focusedService?.discounted_price);
        setProductName(focusedService?.serviceName);
        focusedService?.attrs?.imgs.length > 0 &&
            setCurrentImages([...focusedService?.attrs?.imgs]);
        focusedService?.attrs?.imgs.length > 0 &&
            setImage(focusedService?.attrs?.imgs[0]);
        focusedService?.attrs?.imgs.length > 0 && setSelectedImage(0);
    }

    useEffect(() => {
        if (isLoginRegisterPopupVisible) {
            try {
                document
                    .getElementsByClassName("fade modal show")[0]
                    .removeAttribute("tabindex");
            } catch (e) {
                // leave it alone
            }
        }
    }, [isLoginRegisterPopupVisible]);

    function onClickHire() {
        if (allVariations === undefined || allVariations === null) {
            setHireModal(true);
        } else {
            for (const variationKey of Object.keys(allVariations)) {
                if (!Object.keys(selectedVariation).includes(variationKey)) {
                    alert.error(`Please select ${variationKey}`);
                } else {
                    setHireModal(true);
                }
            }
        }
    }

    async function addServiceToCart(event) {
        event.preventDefault();
        if (state.authenticated) {
            if (quantity < 1) {
                alert.error("Quantity should be atleast 1");
                return 0;
            } else if (length < 1 && focusedService.unit !== "") {
                alert.error("Length should be atleast 1");
                return 0;
            } else if (width < 1 && focusedService.unit === "Sq") {
                alert.error("Width should be atleast 1");
                return 0;
            }
            if (focusedService.unit === "Sq ft") {
                const res = await api.post(
                    `/servicescart`,
                    {
                        quantity,
                        service_id: focusedService._id,
                        variation: {
                            ...selectedVariation,
                            length,
                            width,
                        },
                    },
                    {
                        headers: {
                            cityId: state?.selectedCity,
                        },
                    }
                );
                if (res.data.err) {
                    alert.error(res.data.msg);
                } else {
                    hideReviewModal();
                    setCartLength(cartLength + 1);
                    setIsCartNotifPopupVisible(true);
                    setIsServicePopupVisible(false);
                    alert.success(res.data.msg);
                }
            } else if (focusedService.unit === "R ft") {
                const res = await api.post(`/servicescart`, {
                    quantity,
                    service_id: focusedService._id,
                    variation: {
                        ...selectedVariation,
                        length,
                    },
                });
                if (res.data.err) {
                    alert.error(res.data.msg);
                } else {
                    hideReviewModal();
                    setCartLength(cartLength + 1);
                    setIsCartNotifPopupVisible(true);
                    setIsServicePopupVisible(false);
                    alert.success(res.data.msg);
                }
            } else {
                const res = await api.post(`/servicescart`, {
                    quantity,
                    service_id: focusedService._id,
                    variation: {
                        ...selectedVariation,
                    },
                });
                if (res.data.err) {
                    alert.error(res.data.msg);
                } else {
                    hideReviewModal();
                    setCartLength(cartLength + 1);
                    setIsCartNotifPopupVisible(true);
                    setIsServicePopupVisible(false);
                    alert.success(res.data.msg);
                }
            }
        } else {
            setIsLoginRegisterPopupVisible(true);
        }
    }

    const hideReviewModal = () => {
        setHireModal(false);
    };

    function truncate2(text) {
        return text?.length > 150 ? text.substring(0, 150) + "..." : text;
    }

    let isSmall = useMediaQuery({
        query: "(max-width:992px)",
    });

    useEffect(() => {
        async function fetchData() {
            window.scroll(0, 0);
            setIsServicesLoading(true);
            var dec = genSaltSync(10);
            var hash = hashSync(REACT_APP_HKEY, dec);
            const secret = fromString(REACT_APP_JKEY);
            const twj = await new jose.SignJWT({ expiry: hash })
                .setProtectedHeader({ alg: "HS256" })
                .setExpirationTime("5s")
                .sign(secret);

            const res = await axios.get(
                `${API_URL}/services/getServicesSubCategory/${params?.id}`,
                {
                    params: { perPage, page },
                    headers: {
                        "Content-Policy": twj,
                        cityId: state.selectedCity,
                    },
                }
            );
            if (res.data?.length === 0) {
                setIsError(true);
            } else {
                setIsError(false);
                setTotalPages(res.data.totalPages)
                setServicesSubCategory(res.data.data);
            }
            // if (state.authenticated || !state.accessToken === null) {
            //   const cartRes = await api.get("/servicescart", {
            //     headers: {
            //       cityId: state?.selectedCity,
            //     },
            //   });
            //   if (cartRes.data.error) {
            //   } else {
            //     setCartLength(cartRes?.data?.cartItems.length);
            //     cartLength > 0 && setIsCartNotifPopupVisible(true);
            //   }
            // }

            var decc = genSaltSync(10);
            var hashh = hashSync(REACT_APP_HKEY, decc);
            const secrett = fromString(REACT_APP_JKEY);
            const twjj = await new jose.SignJWT({ expiry: hashh })
                .setProtectedHeader({ alg: "HS256" })
                .setExpirationTime("5s")
                .sign(secrett);
            const response = await axios.get(
                `${API_URL}/services/getServicesCategory/${params?.id}`,
                {
                    headers: {

                        "Content-Policy": twjj,
                    },
                    params: { page },
                }
            );
            if (response.data?.length === 0) {
                setIsError(true);
            } else {
                setIsError(false);
                setServicesCategory(response.data);
            }
            setIsServicesLoading(false);

        }
        state.selectedCity && fetchData();
    }, [state.selectedCity, page]);

    useEffect(() => {
        async function fetchData() {
            if (state.authenticated) {
                setRandomProps(Math.random);
            }
        }
        fetchData();
    }, [state.authenticated, cartLength]);

    useEffect(() => {
        setLength(1);
        setWidth(2);
        setQuantity(1);
        setProductName(focusedService?.serviceName);
        setServicePrice(focusedService?.discounted_price);
        setAllVariations(focusedService?.vars);
        setSelectedVariation({});
        focusedService?.attrs?.imgs &&
            setCurrentImages([...focusedService?.attrs?.imgs]);
        setImage(focusedService?.attrs?.imgs[0]);
        setSelectedImage(0);
    }, [focusedService]);

    useEffect(() => {
        if (focusedService.unit === "Sq ft") {
            setTotalUnits(length * width * quantity);
            setTotalPrice(length * width * quantity * servicePrice);
        } else if (focusedService.unit === "R ft") {
            setTotalUnits(length * quantity);
            setTotalPrice(length * quantity * servicePrice);
        } else {
            setTotalUnits(quantity);
            setTotalPrice(quantity * servicePrice);
        }
    }, [quantity, length, width, servicePrice]);

    const [showModal, setShowModal] = useState(state.selectedCity ? false : true);

    const closeModalHandler = () => {
        setShowModal(false);
    };



    const handlePageChange = (event, value) => {
        // console.log(value)
        //   if (query.get!=["page"]) {
        //     searchParams.set(["page"], value)
        //     searchParams.set(["page"], value)


        // } else {
        //     searchParams.append(["page"], value);
        // }
        setpage(value);
        // setSearchParams(searchParams);

    };

    return (
        <>
            {!state.selectedCity && (
                <>
                    <CityModal onClose={closeModalHandler}></CityModal>
                </>
            )}
            <div style={{ display: isServicesLoading ? "block" : "none" }}>
                <div className="shimmermaindiv" style={{ display: "flex", justifyContent: "center", width: "90%" }}>
                    <div className="serv-loading-shimmer">
                        <div className="serv-shimmer-image"></div>
                        <div className="serv-shimmer-details">
                            <div className="serv-shimmer-line"></div>
                            <div className="serv-shimmer-line"></div>
                            <div className="serv-shimmer-line"></div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginRight: "13%", width: "60%", backgroundColor: "  #fcfcfc ", marginLeft: "20%" }}>
                    <div className="serv-loading-shimmer1">
                        <div className="serv-shimmer-image1"></div>
                        <div className="serv-shimmer-details1">
                            <div className="serv-shimmer-line1"></div>

                            <div className="serv-shimmer-line2"></div>
                        </div>
                    </div>
                    <div className="serv-loading-shimmer1">
                        <div className="serv-shimmer-image1"></div>
                        <div className="serv-shimmer-details1">
                            <div className="serv-shimmer-line1"></div>

                            <div className="serv-shimmer-line2"></div>
                        </div>
                    </div>
                    <div className="serv-loading-shimmer1">
                        <div className="serv-shimmer-image1"></div>
                        <div className="serv-shimmer-details1">
                            <div className="serv-shimmer-line1"></div>

                            <div className="serv-shimmer-line2"></div>
                        </div>
                    </div>
                    <div className="serv-loading-shimmer1">
                        <div className="serv-shimmer-image1"></div>
                        <div className="serv-shimmer-details1">
                            <div className="serv-shimmer-line1"></div>

                            <div className="serv-shimmer-line2"></div>
                        </div>
                    </div>

                </div>
            </div>


            <div style={{ display: isServicesLoading ? "none" : "block" }}>
                <LoginRegisterPopup
                    isVisible={isLoginRegisterPopupVisible}
                    setIsVisible={setIsLoginRegisterPopupVisible}
                />
                <NavRoute
                    productRouter
                    services
                    title={title}
                    serviceCategoryId={params?.id}
                />
                <div className="container-fluid mb-5 position-relative">
                    <div className="row container mx-auto">
                        <div className="col-md-6 col-12 position-relative imageColumnNPD">
                            <div className="d-flex justify-content-center">
                                <img
                                    className="ServiceCategoryBigImage"
                                    src={
                                        servicesCategory &&
                                        servicesCategory?.category_image?.length > 0 &&
                                        servicesCategory?.category_image[1]
                                    }
                                    alt={servicesCategory && servicesCategory?.serviceCategory}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <h3 className="newHeading">
                                {servicesCategory && servicesCategory?.serviceCategory}
                            </h3>
                            <p className="newText">
                                {servicesCategory && servicesCategory?.category_description}
                            </p>
                        </div>
                    </div>
                    <div className="container threeimage_container pt-2 mb-5 pb-5 px-2 px-lg-0">
                        {/* {isServicesLoading && (
                        <>
                            <div className="mx-auto text-center mt-5 mb-5 d-flex align-items-center justify-content-center newHeading">
                                Loading services...{" "}
                                <CircularProgress
                                    style={{ color: "#ff7703", marginLeft: "10px" }}
                                />
                            </div>
                        </>
                    )} */}
                        {servicesSubCategory.length < 1 && !isServicesLoading && (
                            <>
                                <div className="mx-auto text-center mt-5 mb-5 newHeading">
                                    No services available at this moment!
                                </div>
                            </>
                        )}
                        {servicesSubCategory?.map((subCategory, subCategoryIndex) => (
                            <>
                                <div>
                                    <h2
                                        style={{ textTransform: "capitalize" }}
                                        className="newSubheading"
                                    >
                                        {subCategory.serviceSubcategory}
                                    </h2>
                                    <div className="w-100 d-flex flex-wrap gap-4">
                                        {subCategory.services.map(
                                            (singleService, singleServiceIndex) => (
                                                <>
                                                    <div className="d-flex flex-column align-items-stretch my-3" style={{ maxWidth: "225px", cursor: "pointer" }} onClick={() => {
                                                        setFocusedService(singleService);
                                                        setIsServicePopupVisible(true);
                                                    }}>
                                                        {/* <div className="col-3 d-flex align-items-center justify-content-end pe-2"> */}
                                                        <img
                                                            src={singleService.attrs?.imgs[0]}
                                                            style={{ maxWidth: "225px", width: "100%", aspectRatio: "5 / 4", borderRadius: "15px", objectFit: "cover" }}
                                                        />
                                                        {/* </div> */}
                                                        {/* <div className="col-9 d-flex flex-column align-items-center justify-content-between pe-2"> */}
                                                        <div className="">
                                                            <p
                                                                className="m-0 p-0 newText truncate"
                                                                style={{ textTransform: "capitalize" }}
                                                            >
                                                                {singleService?.serviceName}
                                                            </p>
                                                            <p className="m-0 p-0 newText">
                                                                {singleService?.discounted_price && (
                                                                    <>
                                                                        Rs. {singleService?.discounted_price}{" "}
                                                                        {singleService.unit !== "" && <> </>}
                                                                        {singleService.unit === "Sq ft" && (
                                                                            <>/ sq ft</>
                                                                        )}
                                                                        {singleService.unit === "R ft" && <>/running ft</>}
                                                                    </>
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="">
                                                            {singleService?.discounted_price ? (
                                                                <button
                                                                    className="cartside_total_checkout_services w-100 px-4 py-1 newText"
                                                                    style={{ borderRadius: "4px" }}
                                                                    onClick={() => {
                                                                        setFocusedService(singleService);
                                                                        setIsServicePopupVisible(true);
                                                                    }}
                                                                >
                                                                    Add to cart
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className="changeAddressButton_services w-100 px-4 py-1 newText"
                                                                    style={{ borderRadius: "4px" }}
                                                                    onClick={() => {
                                                                        setFocusedService(singleService);
                                                                        setIsServicePopupVisible(true);
                                                                    }}
                                                                >
                                                                    Get price
                                                                </button>
                                                            )}
                                                        </div>
                                                        {/* </div> */}
                                                    </div>
                                                </>
                                            )
                                        )}
                                    </div>
                                </div>
                            </>
                        ))}
                        <Pagination className="servicePagination" hideNextButton={totalPages === page} hidePrevButton={page === 1} count={totalPages} value={page} siblingCount={3} onChange={handlePageChange} variant="outlined" shape="rounded" />
                    </div>
                </div>
                {
                    <div
                        className={
                            isServicePopupVisible
                                ? "servicePopupBackground servicePopupBackgroundVisible"
                                : "servicePopupBackground"
                        }
                    >
                        <div
                            className={
                                isServicePopupVisible
                                    ? `servicesListPopupVisible servicesListPopup shadow`
                                    : `servicesListPopup`
                            }
                            ref={ref}
                        >
                            <div className="w-100">
                                <div className="container">
                                    <div className="row mt-3">
                                        <div className="col-md-6 col-12 position-relative imageColumnNPD">
                                            <div className="d-flex justify-content-center">
                                                {isSmall ? (
                                                    <InnerImageZoom
                                                        src={
                                                            image
                                                                ? image
                                                                : currentImages[0] ||
                                                                focusedService?.attrs?.imgs[0]
                                                        }
                                                        className="newProductDetailBigImg"
                                                        zoomScale={1.5}
                                                        hideHint={true}
                                                        hasSpacer={true}
                                                    />
                                                ) : (
                                                    <ReactImageMagnify
                                                        style={{ zIndex: 2 }}
                                                        {...{
                                                            smallImage: {
                                                                alt: focusedService?.serviceName,
                                                                isFluidWidth: true,
                                                                src: image ? image : currentImages[0],
                                                            },
                                                            largeImage: {
                                                                src: image ? image : currentImages[0],
                                                                width: 700,
                                                                height: 700,
                                                            },
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className="shopbycategory_boxes smallerimg col-12 col-lg-12 col-md-12 col-sm-12">
                                                {currentImages.map((image, i) => (
                                                    <img
                                                        className={
                                                            i === selectedImage ? "active-image" : ""
                                                        }
                                                        src={image}
                                                        alt=""
                                                        style={{ cursor: "pointer", objectFit: "cover" }}
                                                        onClick={(e) => {
                                                            setImage(image);
                                                            setSelectedImage(i);
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                            <div className="row d-flex justify-content-center align-items-center">
                                                {servicePrice ? (
                                                    <div
                                                        className="newProductBuyNowButton buynow mt-1"
                                                        type="button"
                                                        onClick={() => onClickHire()}
                                                        style={{ textTransform: "uppercase", width: "80%" }}
                                                    >
                                                        Hire
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="getPriceNPD text-center px-4"
                                                        type="button"
                                                        onClick={() => getPrice(focusedService._id)}
                                                        style={{
                                                            textTransform: "uppercase",
                                                            width: "max-content",
                                                        }}
                                                    >
                                                        {isGettingPrice ? "Getting price" : "Get price"}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="row newProductStarRatingRow">
                                                <div className="col-8">
                                                    <StarRating stars={4} avgRate />
                                                </div>
                                                <div className="col-4 text-end d-md-block d-none">
                                                    <Share
                                                        style={{
                                                            fill: "#878787",
                                                            transform: "scale(0.8)",
                                                            cursor: "pointer",
                                                        }}
                                                        className="me-2"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                `${window.location.origin}/hire-artisans/service/${focusedService._id}`
                                                            );
                                                            alert.success("Link copied to clipboard");
                                                        }}
                                                    />
                                                    <Cross
                                                        style={{
                                                            fill: "#878787",
                                                            transform: "scale(0.8)",
                                                            cursor: "pointer",
                                                        }}
                                                        className="me-2"
                                                        onClick={() => {
                                                            setIsServicePopupVisible(false);
                                                            setGetPriceTimestamp(null);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <h2 className="newSubheading">{productName}</h2>
                                            {servicePrice && (
                                                <p className="price mt-3 newText">
                                                    Rs. {servicePrice}{" "}
                                                    {focusedService.unit !== "" && <> </>}
                                                    {focusedService.unit === "Sq ft" && <>/ sq ft</>}
                                                    {focusedService.unit === "R ft" && <>/running ft</>}
                                                </p>
                                            )}
                                            {getPriceTimestamp && (
                                                <div className="newText">
                                                    {getPriceTimestamp > new Date().getTime() ? (
                                                        <p className="m-0 mt-2 countdownTimerNPD newText">
                                                            We will contact you with the price before{" "}
                                                            <Countdown date={getPriceTimestamp} />
                                                        </p>
                                                    ) : (
                                                        <p className="m-0 newText">
                                                            Thank you for your interest in this product.
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                            <div className="variationsSection">
                                                {Object.entries(allVariations || {}).map(
                                                    ([variationName, variationData]) => {
                                                        if (
                                                            variationName !== "color" ||
                                                            variationName !== "colour"
                                                        ) {
                                                            return (
                                                                <>
                                                                    <h3 className="newProductSpecificationsTitle newText">
                                                                        {variationName}
                                                                    </h3>
                                                                    <div className="d-flex flex-row flex-wrap">
                                                                        {variationData.map(
                                                                            (variationQuantity, variationIndex) => (
                                                                                <>
                                                                                    <div
                                                                                        className={
                                                                                            selectedVariation[
                                                                                                variationName
                                                                                            ] ===
                                                                                                variationQuantity?.attrs[
                                                                                                variationName
                                                                                                ]
                                                                                                ? `newProductSelectedVariation newProductVariationBox newText`
                                                                                                : `newProductVariationBox newText`
                                                                                        }
                                                                                        onClick={() => {
                                                                                            selectedVariation[
                                                                                                variationName
                                                                                            ] ===
                                                                                                variationQuantity?.attrs[
                                                                                                variationName
                                                                                                ]
                                                                                                ? deselectVariationHandler(
                                                                                                    variationName,
                                                                                                    variationQuantity?.attrs[
                                                                                                    variationName
                                                                                                    ]
                                                                                                )
                                                                                                : changeVariationHandler(
                                                                                                    variationName,
                                                                                                    variationQuantity.attrs[
                                                                                                    variationName
                                                                                                    ]
                                                                                                );
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            variationQuantity?.attrs[
                                                                                            variationName
                                                                                            ]
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </>
                                                            );
                                                        } else {
                                                            return <></>;
                                                        }
                                                    }
                                                )}
                                            </div>
                                            {productDescription?.length > 1 && (
                                                <>
                                                    <h3 className="newProductSpecificationsTitle newSubheading">
                                                        DESCRIPTION
                                                    </h3>
                                                    <p
                                                        className="newProductDescriptionDescription newText"
                                                        style={{
                                                            fontFamily: "Poppins",
                                                            fontSize: "16px",
                                                            fontWeight: 300,
                                                            lineHeight: "24px",
                                                            letterSpacing: "0em",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {isTruncated ? (
                                                            <>
                                                                {truncate2(productDescription)}{" "}
                                                                {productDescription?.length > 150 && (
                                                                    <span
                                                                        className="newProductReadMore newText"
                                                                        onClick={() => {
                                                                            setIsTruncated(false);
                                                                        }}
                                                                    >
                                                                        Read more
                                                                    </span>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {productDescription}{" "}
                                                                {productDescription?.length > 150 && (
                                                                    <span
                                                                        className="newProductReadMore newText"
                                                                        onClick={() => {
                                                                            setIsTruncated(true);
                                                                        }}
                                                                    >
                                                                        Read less
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                    </p>
                                                    <hr className="newProductDetailHr" />
                                                </>
                                            )}
                                            <h3 className="newProductSpecificationsTitle newSubheading">
                                                TERMS & CONDITIONS
                                            </h3>
                                            <ul className="mb-5 pb-3 newText">
                                                <li>The booking amount is fixed and non-negotiable </li>
                                                <li>
                                                    The Customer shall inspect the work daily or
                                                    alternatively{" "}
                                                </li>
                                                <li>Material is optional </li>
                                                <li>100% refundable cancellation before 72 hours </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    <div>
                        <ServicesCartPopup props={randomprop} />
                    </div>
                }
                <Modal
                    show={hireModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={hideReviewModal}
                    ref={modalRef}
                    tabIndex=""
                    style={{ paddingLeft: 0 }}
                >
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            padding: "10px",
                        }}
                    >
                        <span
                            style={{
                                margin: "0px auto",
                                fontFamily: "Poppins",
                                fontSize: "24px",
                                fontWeight: 600,
                                lineHeight: "36px",
                                letterSpacing: "0em",
                                textAlign: "left",
                            }}
                        >
                            PRICE DETAILS
                        </span>
                        <button
                            type="button"
                            class="btn-close"
                            aria-label="Close"
                            color="#000000"
                            onClick={() => hideReviewModal()}
                        ></button>
                    </div>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <span className="col-8">
                                    <label className="inputLabelsService" id="NameId">
                                        Quantity
                                    </label>
                                    <input
                                        id="NameId"
                                        min={1}
                                        className="inputServiceNum"
                                        type="number"
                                        value={quantity}
                                        onChange={(e) =>
                                            e.target.value > -1 && e.target.value !== null
                                                ? setQuantity(e.target.value)
                                                : alert.error("")
                                        }
                                    />
                                </span>
                                <span className="col-4">
                                    <label className="inputLabelsService" id="NameId">
                                        Per unit charges
                                    </label>
                                    <input
                                        id="NameId"
                                        className="inputServiceNum"
                                        type="number"
                                        value={servicePrice}
                                        onChange={(e) => { }}
                                    />
                                </span>
                            </div>
                            <div className="row align-items-center">
                                {focusedService.unit !== "" && (
                                    <span className="col-4">
                                        <label className="inputLabelsService" id="NameId">
                                            Length (ft)
                                        </label>
                                        <input
                                            id="NameId"
                                            min={0}
                                            className="inputServiceNum"
                                            type="number"
                                            value={length}
                                            onChange={(e) =>
                                                e.target.value > -1 && e.target.value !== null
                                                    ? setLength(e.target.value)
                                                    : alert.error("Length should atleast be 1")
                                            }
                                        />
                                    </span>
                                )}
                                {focusedService.unit === "Sq ft" && (
                                    <>
                                        <>x</>
                                        <span className="col-4">
                                            <label className="inputLabelsService" id="NameId">
                                                Width (ft)
                                            </label>
                                            <input
                                                id="NameId"
                                                min={0}
                                                className="inputServiceNum"
                                                type="number"
                                                value={width}
                                                onChange={(e) =>
                                                    e.target.value > -1 && e.target.value !== null
                                                        ? setWidth(e.target.value)
                                                        : alert.error("Width should atleast be 1")
                                                }
                                            />
                                        </span>
                                    </>
                                )}
                                {focusedService.unit !== "" && <>=</>}
                                <span className="col-3 align-self-end">
                                    <label className="inputLabelsService" id="NameId">
                                        Total Units
                                    </label>
                                    <input
                                        id="NameId"
                                        value={totalUnits}
                                        className="inputServiceNum"
                                        type="number"
                                    />
                                </span>
                            </div>
                            {focusedService.unit === "Sq ft" && (
                                <>
                                    <span
                                        style={{
                                            color: "#878787",
                                            fontSize: "10px",
                                            fontWeight: "400",
                                            fontFamily: "Poppins",
                                        }}
                                    >
                                        *(Length x Width) x Number of units = Total Units
                                    </span>
                                </>
                            )}
                            {focusedService.unit === "R ft" && (
                                <>
                                    <span
                                        style={{
                                            color: "#878787",
                                            fontSize: "10px",
                                            fontWeight: "400",
                                            fontFamily: "Poppins",
                                        }}
                                    >
                                        *(Length) x Number of units = Total Units
                                    </span>
                                </>
                            )}
                            {focusedService.unit === "" && (
                                <>
                                    <span
                                        style={{
                                            color: "#878787",
                                            fontSize: "10px",
                                            fontWeight: "400",
                                            fontFamily: "Poppins",
                                        }}
                                    ></span>
                                </>
                            )}
                            <div className="Totalprice my-5">
                                <span className="inputLabelsService">Total Price</span>
                                <span>Rs. {totalPrice}</span>
                            </div>
                            <div style={{ textAlign: "center", marginBottom: "10px" }}>
                                <button
                                    onClick={(e) => addServiceToCart(e)}
                                    className="hireConButton"
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <Footer />
        </>
    );
}

export default ServiceCategory;