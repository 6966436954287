import axios from "axios";
import { genSaltSync, hashSync } from "bcryptjs";
import * as jose from "jose";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fromString } from "uint8arrays/from-string";
import useAxios from "../../../../utils/useAxios.js";
import {
    API_URL,
    REACT_APP_HKEY,
    REACT_APP_JKEY,
    REACT_APP_GA_MEASUREMENT_ID
} from "../../../../constants.js";
import Footer from "../../../global_component/Footer/Footer";
import NavRoute from "../../Nav_Route/NavRoute";
import "./HireArtisans.css";
import { Helmet } from "react-helmet-async";
import CityModal from "../../../global_component/Modal/cityModal.js";
import { useStore } from "../../../../store/store.js";
import ReactGA from "react-ga4";
import { useAlert } from "react-alert";
import ServicesCartPopup from "./ServicesCartPopup.js";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

export default function Hire() {
    const [state, dispatch] = useStore();
    const api = useAxios();
    const [servicesCategory, setServicesCategory] = useState([]);
    const alert = useAlert();
    const [isError, setIsError] = useState(false);
    const [isLoadingProducts, setIsLoadingProducts] = useState(true);
    const navigate = useNavigate();
    ReactGA.send({
        hitType: 'pageview',
        page: `/hire-artisans`,
        title: 'Hire Artisans'
    })
    useEffect(() => {
        async function fetchData() {
            window.scroll(0, 0)
            setIsLoadingProducts(true);
            var dec = genSaltSync(10);
            var hash = hashSync(REACT_APP_HKEY, dec);
            const secret = fromString(REACT_APP_JKEY);
            const twj = await new jose.SignJWT({ expiry: hash })
                .setProtectedHeader({ alg: "HS256" })
                .setExpirationTime("5s")
                .sign(secret);
            const res = await axios.get(`${API_URL}/services/getSectionwiseServicesCategory`, {
                headers: {
                    "Content-Policy": twj,
                },
            });
            if (res.data?.length === 0) {
                setIsError(true);
            } else {
                setIsError(false);
                setServicesCategory(res.data.filter(data => data.page === "hire-artisans"));
            }
            setIsLoadingProducts(false);
        }
        fetchData();
    }, []);

    return (
        <>
            <Helmet>
                <meta
                    name="title"
                    content={`Book ${servicesCategory?.serviceCategory} with plypicker at best price in maharashtra | upto 75% discount | your service at your booked time.`}
                />
                <meta
                    name="description"
                    content={`Book ${servicesCategory?.serviceCategory} with plypicker at best price in maharastra and reduce your hassle of searching interior designing related workers from market and get your {sub Category name} delivered at your door step in less than 48 hr so that your work never stops`}
                />
                <meta
                    name="keywords"
                    content="plypicker, ecommerce, interior designer, interior designing, plywood, basin, faucet, hardware, flat, wardrobe, bathroom, kitchen"
                />
                <meta property="og:image" content="LOGO" />
                <link rel="canonical" href="https://plypicker.com/hire-artisans" />
            </Helmet>
            <>
                <div style={{ display: isLoadingProducts ? "block" : "none" }}>
                    <div style={{ display: "flex", overflowX: "none" }}>
                        <div className="comp4-loading-shimmer">
                            <div className="comp4-shimmer-image"></div>
                            <div className="comp4-shimmer-details">
                                <div className="comp4-shimmer-line"></div>
                                <div className="comp4-shimmer-line"></div>
                                <div className="comp4-shimmer-line"></div>
                            </div>
                        </div>
                        <div className="comp4-loading-shimmer">
                            <div className="comp4-shimmer-image"></div>
                            <div className="comp4-shimmer-details">
                                <div className="comp4-shimmer-line"></div>
                                <div className="comp4-shimmer-line"></div>
                                <div className="comp4-shimmer-line"></div>
                            </div>
                        </div>
                        <div className="comp4-loading-shimmer">
                            <div className="comp4-shimmer-image"></div>
                            <div className="comp4-shimmer-details">
                                <div className="comp4-shimmer-line"></div>
                                <div className="comp4-shimmer-line"></div>
                                <div className="comp4-shimmer-line"></div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div className="comp4-loading-shimmer">
                            <div className="comp4-shimmer-image"></div>
                            <div className="comp4-shimmer-details">
                                <div className="comp4-shimmer-line"></div>
                                <div className="comp4-shimmer-line"></div>
                                <div className="comp4-shimmer-line"></div>
                            </div>
                        </div>
                        <div className="comp4-loading-shimmer">
                            <div className="comp4-shimmer-image"></div>
                            <div className="comp4-shimmer-details">
                                <div className="comp4-shimmer-line"></div>
                                <div className="comp4-shimmer-line"></div>
                                <div className="comp4-shimmer-line"></div>
                            </div>
                        </div>
                        <div className="comp4-loading-shimmer">
                            <div className="comp4-shimmer-image"></div>
                            <div className="comp4-shimmer-details">
                                <div className="comp4-shimmer-line"></div>
                                <div className="comp4-shimmer-line"></div>
                                <div className="comp4-shimmer-line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <div style={{ display: isLoadingProducts ? "none" : "block" }}>
                <div className="container-fluid mt-5">
                    <div className="p-2 mb-4 flex row justify-center align-center py-5">
                        <div className="col-5 d-none d-sm-flex justify-content-center align-items-center">
                            <img src={`${servicesCategory[0]?.img}`} className="text-center margin-auto" style={{ borderRadius: "50px", maxWidth: "100%", maxHeight: "400px", aspectRatio: 1, objectFit: "cover" }} />
                        </div>
                        <div className="col-sm-7 col-0 d-flex flex-column justify-content-center text-center">
                            <h1>Hire Artisans</h1>
                            <h5 className="py-3">{servicesCategory[0]?.sectionName}</h5>
                            <p style={{ maxWidth: "70%", marginLeft: "auto", marginRight: "auto" }}>{servicesCategory[0]?.content}</p>
                            <Link to={"/hire-artisans?material=true"} style={{ width: "200px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none", background: "#FF7703", color: "white", borderRadius: "10px", marginLeft: "auto", marginRight: "auto" }}>Book now</Link>
                        </div>
                    </div>
                    <div className="p-2 mb-4 flex row justify-center align-center py-5">
                        <div className="col-sm-7 col-0 d-flex flex-column justify-content-center text-center">
                            <h1>Hire Artisans</h1>
                            <h5 className="py-3">{servicesCategory[1]?.sectionName}</h5>
                            <p style={{ maxWidth: "70%", marginLeft: "auto", marginRight: "auto" }}>{servicesCategory[1]?.content}</p>
                            <Link to={"/hire-artisans?material=false"} style={{ width: "200px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none", background: "#FF7703", color: "white", borderRadius: "10px", marginLeft: "auto", marginRight: "auto" }}>Book now</Link>
                        </div>
                        <div className="col-5 d-none d-sm-flex justify-content-center align-items-center">
                            <img src={`${servicesCategory[1]?.img}`} className="text-center margin-auto" style={{ borderRadius: "50px", maxWidth: "100%", maxHeight: "400px", aspectRatio: 1, objectFit: "cover" }} />
                        </div>
                    </div>
                </div>
                <ServicesCartPopup />
            </div>
            <Footer />
        </>
    );
}