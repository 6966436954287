import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ErrorPage from "../Components/global_component/ErrorPage/ErrorPage";
import BuyNow from "../Components/local_component/BuyNow/BuyNow";
import AboutUs from "../Components/local_component/conditons_policy/AboutUs";
import ContactUS from "../Components/local_component/conditons_policy/ContactUS";
import FAQ from "../Components/local_component/conditons_policy/FAQ";
import PrivacyPolicy from "../Components/local_component/conditons_policy/PrivacyPolicy";
import ReturnPolicy from "../Components/local_component/conditons_policy/ReturnPolicy";
import ShippingDelivery from "../Components/local_component/conditons_policy/ShippingDelivery";
import TC from "../Components/local_component/conditons_policy/TC";
import Homepage from "../Components/local_component/Homepage/Homepage";
import Building from "../Components/local_component/Homepage/Ideas/Building";
import Homepage2 from "../Components/local_component/Homepage/Homepage2";
import HireArtisans from "../Components/local_component/Homepage/Ideas/HireArtisans";
import InteriorDesign from "../Components/local_component/Homepage/Ideas/InteriorDesign";
import ServiceCategory from "../Components/local_component/Homepage/Ideas/ServiceCategory";
import ServiceDetails from "../Components/local_component/Homepage/Ideas/ServiceDetails";
import Services from "../Components/local_component/Homepage/Ideas/Services";
import VisitShowroom from "../Components/local_component/Homepage/Ideas/VisitShowroom";
import IndividualProducts from "../Components/local_component/IndividualProducts/IndividualProducts";
import NewProductList from "../Components/local_component/IndividualProducts/NewProductList/NewProductList";
import RatingReview from "../Components/local_component/IndividualProducts/NewProductList/RatingAndReview/RatingReview";
import SignIn from "../Components/local_component/Login/SignIn";
import Addresses from "../Components/local_component/ProfileRoute/ProfilePage/Addresses/Addresses";
import Cart from "../Components/local_component/ProfileRoute/ProfilePage/Cart/Cart";
import Notification from "../Components/local_component/ProfileRoute/ProfilePage/Notification/Notification";
import OrderSummary from "../Components/local_component/ProfileRoute/ProfilePage/OrderSummary/OrderSummary";
import Payment from "../Components/local_component/ProfileRoute/ProfilePage/Payment/Payment";
import Account from "../Components/local_component/ProfileRoute/ProfilePage/ProfilePageInside/Account";
import Profile from "../Components/local_component/ProfileRoute/ProfilePage/ProfilePageInside/ProfilePage";
import ResetPassword from "../Components/local_component/ProfileRoute/ProfilePage/ResetPassword/ResetPassword";
import Review from "../Components/local_component/ProfileRoute/ProfilePage/Review/Review";
import ServicesCart from "../Components/local_component/ProfileRoute/ProfilePage/ServicesCart/ServicesCart";
import Wishlist from "../Components/local_component/ProfileRoute/ProfilePage/WIshlist/Wishlist";
import OrderDetails from "../Components/local_component/ProfileRoute/ProfilePage/YourOrders/OrderDetails";
import YourOrders from "../Components/local_component/ProfileRoute/ProfilePage/YourOrders/YourOrders";
import SignUp from "../Components/local_component/Register/SignUp";
import Invoice from "../Components/local_component/Invoice/Invoice.js"
import NavbarLayout from "../routes/NavbarCustomRoute";
import OnlyUnauthRoute from "../routes/OnlyUnauthRoute";
import ProtectedRoute from "../routes/ProtectedRoute";
import ServiceTC from "../Components/local_component/Homepage/Ideas/ServiceTC";
import Hire from "../Components/local_component/Homepage/Ideas/Hire.js";

const AllRoutes = ({ categories }) => {
    return (
        <Router>
            <NavbarLayout categories={categories} />
            <Routes>
                <Route path="/" element={<Homepage2 />} />
                <Route element={<ProtectedRoute redirectTo="/signin" />}>
                    <Route path="/profile" element={<Profile />} />
                </Route>
                <Route element={<ProtectedRoute redirectTo="/signin" />}>
                    <Route path="/account" element={<Account />} />
                </Route>
                <Route element={<ProtectedRoute redirectTo="/signin" />}>
                    <Route path="/addresses" element={<Addresses />} />
                </Route>
                <Route element={<ProtectedRoute redirectTo="/signin" />}>
                    <Route path="/wishlist" element={<Wishlist />} />
                </Route>
                <Route element={<ProtectedRoute redirectTo="/signin" />}>
                    <Route path="/cart" element={<Cart />} />
                </Route>
                <Route element={<ProtectedRoute redirectTo="/signin" />}>
                    <Route path="/ordersummary" element={<OrderSummary />} />
                </Route>
                <Route element={<ProtectedRoute redirectTo="/signin" />}>
                    <Route path="/payment" element={<Payment />} />
                </Route>
                <Route element={<ProtectedRoute redirectTo="/signin" />}>
                    <Route path="/notif" element={<Notification />} />
                </Route>
                <Route element={<ProtectedRoute redirectTo="/signin" />}>
                    <Route path="/orders" element={<YourOrders />} />
                </Route>
                <Route element={<ProtectedRoute redirectTo="/signin" />}>
                    <Route path="/order/:id" element={<OrderDetails />} />
                </Route>
                <Route element={<ProtectedRoute redirectTo="/signin" />}>
                    <Route path="/buynow" element={<BuyNow />} />
                </Route>
                <Route element={<ProtectedRoute redirectTo="/signin" />}>
                    <Route path="/review" element={<Review />} />
                </Route>
                <Route element={<ProtectedRoute redirectTo="/signin" />}>
                    <Route path="/services/cart" element={<ServicesCart />} />
                </Route>
                <Route path="/product/:p_id" element={<IndividualProducts />} />
                <Route element={<OnlyUnauthRoute redirectTo="/profile" />}>
                    <Route path="/signin" element={<SignIn />} />
                </Route>
                <Route element={<OnlyUnauthRoute redirectTo="/profile" />}>
                    <Route path="/signup" element={<SignUp />} />
                </Route>
                <Route path="/invoices/:invoiceId" element={<Invoice type="product" />} />
                <Route path="/serviceinvoices/:invoiceId" element={<Invoice type="service" />} />
                <Route path="/app/invoices/:invoiceId" element={<Invoice type="product" />} />
                <Route path="/app/serviceinvoices/:invoiceId" element={<Invoice type="service" />} />
                <Route path="/interior-design" exact element={<InteriorDesign />} />
                <Route path="/shipping-and-delivery-policy" exact element={<ShippingDelivery />} />
                <Route path="/building" exact element={<Building />} />
                <Route path="/visit-showroom" exact element={<VisitShowroom />} />
                <Route path="/hire" exact element={<Hire />} />
                <Route path="/hire-artisans" exact element={<HireArtisans />} />
                <Route path="/hire-artisans/:id" exact element={<ServiceCategory title={"Hire Artisans"} />} />
                <Route path="/interior-design/:id" exact element={<ServiceCategory title={"Interior Design"} />} />
                <Route path="/hire-artisans/:id/:sid" exact element={<Services />} />
                <Route path="/hire-artisans/service/:id" exact element={<ServiceDetails />} />
                <Route path="/productlist" exact element={<NewProductList />} />
                <Route path="/rating-review/undefined" element={<ErrorPage />} />
                <Route path="/rating-review/:id" element={<RatingReview />} />
                <Route path="/reset-password/:userId/:token" element={<ResetPassword />} />
                <Route path="/service/terms-and-condition/:aid" element={<ServiceTC />} />
                <Route path="/terms-and-condition" element={<TC />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/cancellation-refund-policy" element={<ReturnPolicy />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contact-us" element={<ContactUS />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="*" exact element={<ErrorPage />} />
            </Routes>
        </Router>
    );
};

export default AllRoutes;
